import '../../styles/elements/inputs/default-input.scss'

export default function DefaultInput(
    {className = "", title, value, onChangeFunction}
) {
    return (
        <div className={"input-contain " + className}>
            <input
                type="text"
                id="fname"
                name="fname"
                autoComplete="off"
                value={value}
                aria-labelledby="placeholder-fname"
                onChange={onChangeFunction}
            />
            <label className="placeholder-text" htmlFor="fname" id="placeholder-fname">
                <div className="text">{title}</div>
            </label>

        </div>
    )
}
