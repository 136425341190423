import '../../styles/elements/inputs/submit-button.scss'

export default function SubmitButton(
    {className = "", text, onClick, disabled = false}
) {
    return (
        <button className={'submit-button ' + className} onClick={onClick} disabled={disabled}>
            {text}
        </button>
    )
}
